<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="info.type" placeholder="请选择">
            <el-option
                v-for="item in menuList"
                :key="item.id"
                :label="item.title"
                :value="item.id">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <SelectImgs :selectType="[1, 2]" :selectNum="1" :selectData="[info.img]" :selectFunc="picChoose"></SelectImgs>
        <div style="margin-top: 40px" v-if="info.type==1">1920*850px（图片或视频）</div>
        <div style="margin-top: 40px" v-else>1920*680px</div>
      </el-form-item>
      <el-form-item label="链接" prop="url">
        <el-input v-model="info.url" placeholder="请输入链接"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
export default {
  data() {
    return {
      info: {
        id: 0,
        img: '',
        title: '',
        url: ''
      },
      menuList: [
        {id:1,title:'首页banner'},
        {id:2,title:'产品列表'},
        {id:3,title:'解决方案'},
        {id:4,title:'关于我们'},
        {id:5,title:'招贤纳士'},
        {id:6,title:'联系我们'},
        {id:7,title:'专业服务'},
        {id:8,title:'新闻列表'},
        {id:9,title:'展会列表'},
      ],
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
      }
    };
  },
  components: {
    SelectImgs
  },
  created() {
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    picChoose(item) {
      if (item.file_type === 2) {
        this.info.img = item.url
      } else {
        this.info.img = item.pic
      }
    },
    getInfo() {
      var that = this
      that.showLoading()
      this.$api.merchant.bannerInfo({id: that.info.id}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          this.$api.merchant.bannerEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
</style>
